<template>
  <main class="app-container">
    <breadcrumb />
    <div class="about-content w1200">
      六脉小包是九方通逊旗下倾力打造的直发类专线物流品牌，致力于为独立站、平台卖家、品牌出海客户提供稳定、快捷、高性价比的B2C包裹直发物流解决方案。
      自有物流软件研发团队，全程轨迹透明、清关稳妥、性价比高，服务范围覆盖美国、英国、德国等国家，同时可以提供国内头程仓、一件代发等增值服务！
    </div>
    <div class="company-history w1200">
      <div class="history-item">
        <p class="history-text">六脉成立</p>
        <p class="history-year">2019年</p>
        <p class="history-icon">
          <img src="../../assets/images/about/air-icon.png" alt="" />
        </p>
      </div>
      <div class="history-item">
        <p class="history-text">开通德国专线</p>
        <p class="history-year">2020年</p>
        <p class="history-icon">
          <img src="../../assets/images/about/air-icon.png" alt="" />
        </p>
      </div>
      <div class="history-item">
        <p class="history-text">开通美国专线</p>
        <p class="history-year">2022年</p>
        <p class="history-icon">
          <img src="../../assets/images/about/air-icon.png" alt="" />
        </p>
      </div>
      <div class="history-item">
        <p class="history-text">
          开通英国专线<br />
          开通荷兰专线<br />
          成立厦门分公司<br />
          成立上海分公司
        </p>
        <p class="history-year">2023年</p>
        <p class="history-icon">
          <img src="../../assets/images/about/air-icon.png" alt="" />
        </p>
      </div>
    </div>

    <div class="about-carousel">
      <div class="bg-panel"></div>
      <div class="w1200 carousel-box">
        <carousel-3d
          :autoplay="true"
          :autoplayTimeout="3000"
          :perspective="0"
          :display="3"
          :animationSpeed="1000"
          :width="547"
          :height="252"
          controlsVisible
          :controlsHeight="50"
          :controlsWidth="25"
        >
          <slide v-for="(item, i) in slides" :index="i" :key="i">
            <img :src="item.src" />
          </slide>
        </carousel-3d>
      </div>
    </div>

    <div class="customer-service w1200">
      <div class="service-title">寻求以客户需求为导向的服务</div>
      <div class="service-list">
        <div class="service-item">
          <div class="number-flag">1</div>
          <div class="service-bg"></div>
          <div class="service-icon">
            <img
              width="54px"
              height="54px"
              src="../../assets/images/about/service-icon1.png"
              alt=""
            /><img
              width="54px"
              height="54px"
              src="../../assets/images/about/service-white-icon1.png"
              alt=""
            />
          </div>
          <div class="service-content">
            <p class="service-name">全链路服务能力</p>
            <p class="service-text">
              在确保全链路服务体验基础上，整合各环节资源和<br />
              优势，建立竞争门槛
            </p>
          </div>
        </div>
        <div class="service-item">
          <div class="number-flag">2</div>
          <div class="service-bg"></div>
          <div class="service-icon">
            <img
              width="53px"
              height="53px"
              src="../../assets/images/about/service-icon2.png"
              alt=""
            /><img
              width="53px"
              height="53px"
              src="../../assets/images/about/service-white-icon2.png"
              alt=""
            />
          </div>
          <div class="service-content">
            <p class="service-name">物流产品设计能力</p>
            <p class="service-text">
              深入了解国际物流各环节痛点，帮助企业量身定<br />
              制最佳的物流产品方案
            </p>
          </div>
        </div>
        <div class="service-item">
          <div class="number-flag">3</div>
          <div class="service-bg"></div>
          <div class="service-icon">
            <img
              width="51px"
              height="57px"
              src="../../assets/images/about/service-icon3.png"
              alt=""
            /><img
              width="51px"
              height="57px"
              src="../../assets/images/about/service-white-icon3.png"
              alt=""
            />
          </div>
          <div class="service-content">
            <p class="service-name">信息化建设能力</p>
            <p class="service-text">
              自主开发的小包系统 自有研发团队全程物流轨迹<br />
              追踪
            </p>
          </div>
        </div>
        <div class="service-item">
          <div class="number-flag">4</div>
          <div class="service-bg"></div>
          <div class="service-icon">
            <img
              width="52px"
              height="54px"
              src="../../assets/images/about/service-icon4.png"
              alt=""
            /><img
              width="52px"
              height="54px"
              src="../../assets/images/about/service-white-icon4.png"
              alt=""
            />
          </div>
          <div class="service-content">
            <p class="service-name">全产品线服务能力</p>
            <p class="service-text">
              提供涵盖中邮代理，以及国际专线、海外仓转运<br />
              和各国海外仓储等物流产品
            </p>
          </div>
        </div>
        <div class="service-item">
          <div class="number-flag">5</div>
          <div class="service-bg"></div>
          <div class="service-icon">
            <img
              width="46px"
              height="46px"
              src="../../assets/images/about/service-icon5.png"
              alt=""
            /><img
              width="46px"
              height="46px"
              src="../../assets/images/about/service-white-icon5.png"
              alt=""
            />
          </div>
          <div class="service-content">
            <p class="service-name">海外运营能力</p>
            <p class="service-text">
              海外仓服务覆盖美加日英德法意西主要跨境电商出<br />
              口国家市场；借助海外仓规模体系提高物流后端的<br />
              服务配套能力
            </p>
          </div>
        </div>
        <div class="service-item">
          <div class="number-flag">6</div>
          <div class="service-bg"></div>
          <div class="service-icon">
            <img
              width="43px"
              height="59px"
              src="../../assets/images/about/service-icon6.png"
              alt=""
            /><img
              width="43px"
              height="59px"
              src="../../assets/images/about/service-white-icon6.png"
              alt=""
            />
          </div>
          <div class="service-content">
            <p class="service-name">海外问题件处理能力</p>
            <p class="service-text">
              丢件，退件重派等疑难问题件24小时人工客服<br />
              服务，随时跟踪，保障客户包裹能安全及时到达
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-way w1200">
      <div class="contact-title">联系方式</div>
      <div class="contact-list">
        <div class="contact-item">
          <p class="contact-label">业务咨询</p>
          <p class="contact-text">13728680552</p>
        </div>
        <div class="contact-item">
          <p class="contact-label">商务合作</p>
          <p class="contact-text">
            <a href="mailto:Carl.zhu@tofba.com">Carl.zhu@tofba.com</a>
          </p>
        </div>
        <div class="contact-item">
          <p class="contact-label">投诉与建议</p>
          <p class="contact-text">
            <a href="mailto:Carl.zhu@tofba.com">Carl.zhu@tofba.com</a>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import Breadcrumb from "@/components/Breadcrumb";
import carouselImg1 from "@/assets/images/about/carousel-image1.png";
import carouselImg2 from "@/assets/images/about/carousel-image2.jpg";
import carouselImg3 from "@/assets/images/about/carousel-image3.jpg";
import carouselImg4 from "@/assets/images/about/carousel-image4.jpg";
export default {
  components: {
    Breadcrumb,
    Carousel3d,
    Slide,
  },
  name: "about",
  data() {
    return {
      slides: [
        {
          src: carouselImg1,
        },
        {
          src: carouselImg2,
        },
        {
          src: carouselImg3,
        },
        {
          src: carouselImg4,
        },
      ],
    };
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.about-content {
  font-size: 16px;
  color: #282727;
  line-height: 26px;
}
.company-history {
  position: relative;
  height: 570px;
  margin-top: 80px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/about/history-bg.png");
  background-size: 100% 360px;
  background-position: bottom;
  .history-item {
    position: absolute;
    text-align: center;
    &:first-child {
      bottom: 173px;
      left: 152px;
    }
    &:nth-child(2) {
      bottom: 314px;
      left: 425px;
    }
    &:nth-child(3) {
      bottom: 314px;

      right: 425px;
    }
    &:nth-child(4) {
      bottom: 173px;
      right: 152px;
    }
    .history-text {
      color: #626262;
      line-height: 24px;
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 101px;
        background: #959595;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
    .history-year {
      font-size: 22px;
      font-family: SourceHanSansCN-Bold;
      color: #282727;
      margin: 10px 0 10px;
    }
    .history-icon {
      width: 81px;
      height: 81px;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.about-carousel {
  .bg-panel {
    height: 186px;
    width: 100%;
    background-image: url(../../assets/images/about/carousel-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .carousel-box {
    margin-top: -85px;
    ::v-deep .carousel-3d-slide:not(.current) {
      filter: blur(2px) !important;
    }
    ::v-deep .carousel-3d-controls {
      .prev {
        background-color: #ececec;
        font-size: inherit;
        &::after {
          line-height: 50px;
          font-size: 25px;
          color: #a4a4a4;
          content: "\e6de";
          font-family: element-icons !important;
        }
        left: 92px;
        span {
          display: none;
        }
      }
      .next {
        font-size: inherit;
        background-color: #ececec;
        &::after {
          line-height: 50px;
          font-size: 25px;
          color: #a4a4a4;
          content: "\e6e0";
          font-family: element-icons !important;
        }
        span {
          display: none;
        }
        right: 92px;
      }
    }
  }
}

.service-title,
.contact-title {
  font-size: 34px;
  line-height: 57px;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  color: #e2385c;
  &::before {
    display: block;
    height: 48px;
    position: absolute;
    color: #457fda;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateX(-50%);
    top: -59%;
    left: 50%;
    content: "";
  }
}
.customer-service {
  margin-top: 100px;
  .service-title {
    &::before {
      width: 498px;
      background-image: url(../../assets/images/about/service-title.png);
    }
  }
  .service-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .service-item {
      width: 574px;
      height: 187px;
      border: 1px solid rgba($color: #000000, $alpha: 0.2);
      margin-top: 20px;
      background-repeat: no-repeat;
      background-clip: border-box;
      background-position-x: calc(100% - 18px);
      padding: 44px 0 44px 41px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .number-flag {
        position: absolute;
        top: 0;
        left: 20px;
        width: 34px;
        height: 39px;
        background-image: url(../../assets/images/about/number-flag.png);
        background-size: 100%;
        color: #fff;
        font-size: 18px;
        line-height: 39px;
        text-align: center;
      }
      .service-icon {
        width: 99px;
        height: 99px;
        background: #f7f7f9;
        border-radius: 50%;
        text-align: center;
        line-height: 99px;
        margin-right: 45px;
        img {
          vertical-align: middle;
        }
        & img:last-child {
          display: none;
        }
      }
      .service-name {
        font-size: 18px;
        font-family: SourceHanSansCN-Bold;
        color: #2e2e2e;
        margin-bottom: 15px;
      }
      .service-text {
        font-size: 14px;
        color: #444444;
        line-height: 21px;
      }
      .service-bg {
        position: absolute;
        right: 20px;
        background-size: 100%;
        display: none;
      }

      &:first-child .service-bg {
        width: 146px;
        height: 153px;
        background-image: url(../../assets/images/about/service-item-bg1.png);
        top: 33px;
      }
      &:nth-child(2) .service-bg {
        background-image: url(../../assets/images/about/service-item-bg2.png);
        width: 155px;
        height: 148px;
        top: 36px;
      }
      &:nth-child(3) .service-bg {
        background-image: url(../../assets/images/about/service-item-bg3.png);
        width: 160px;
        height: 179px;
        top: 6px;
      }
      &:nth-child(4) .service-bg {
        background-image: url(../../assets/images/about/service-item-bg4.png);
        width: 144px;
        height: 149px;
        top: 29px;
      }
      &:nth-child(5) .service-bg {
        background-image: url(../../assets/images/about/service-item-bg5.png);
        width: 157px;
        height: 167px;
        top: 17px;
      }
      &:nth-child(6) .service-bg {
        background-image: url(../../assets/images/about/service-item-bg6.png);
        width: 152px;
        height: 168px;
        top: 16px;
      }
      &:hover {
        border: 1px solid #4d80d9;
        background: linear-gradient(
          131deg,
          #ffffff 0%,
          #ffffff 46%,
          #e7f0fe 99%
        );
        box-shadow: 4px 8px 12px 1px rgba(150, 162, 176, 0.46);
        opacity: 0.8;
        transition: all 0.7;
        .service-icon {
          background-color: #2065da;
          img:first-child {
            display: none;
          }
          img:last-child {
            display: inline-block;
          }
        }
        .service-bg {
          display: block;
        }
      }
    }
  }
}
.contact-way {
  margin-top: 100px;
  margin-bottom: 100px;
  .contact-title {
    &::before {
      width: 223px;
      background-image: url(../../assets/images/about/about-title.png);
    }
  }
  .contact-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .contact-item:nth-child(2) {
      padding-left: 80px;
    }
    .contact-label {
      font-size: 20px;
      font-family: SourceHanSansCN-Bold;
      color: #252525;
      margin-bottom: 15px;
    }
    .contact-text {
      font-size: 16px;
      font-family: SourceHanSansCN-Normal;
      color: #252525;
      a {
        color: #252525;
      }
    }
  }
}
</style>
